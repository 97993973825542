<template>
  <v-row no-gutters class="text-center">
    <h1>About page</h1>
  </v-row>
</template>

<script>
export default {
  name: "About"
};
</script>
